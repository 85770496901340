import React, { useState} from 'react';
import './Best.css';
import { Col, Row, Button } from 'react-bootstrap'
import Mod from '../utils/Mod';

function Best() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
        <Row className='py-5' id='Best'>
            <Col sm={7} className='colFirst'>
                <h2 className='H2Best'><span style={{fontWeight:'400'}}>BEST INTERIOR DESIGNERS</span> IN BANGALORE</h2>
                <p className='PBest'>The design and ambiance of your home’s interior are as crucial as any other element within your dwelling. While much emphasis is often placed on architecture during construction or renovation, a home truly becomes a haven only when equal attention is dedicated to its interior design and decoration. This is where the expertise of top home interior designers in Bangalore comes into play, transforming architectural gems into design masterpieces. Whether you’re in search of experts for bedroom design, modular kitchen design, or any other home interior needs, Introspace is the ultimate destination for all your design requirements.</p>
           
                <h3 className='H2Best' style={{textTransform:'uppercase'}}><span style={{fontWeight:'400'}}>Bangalore BEST HOME</span> INTERIOR COMPANY</h3>
                <p className='PBest'>Introspace- Big Price Challenge. Get Premium & Luxury Home Interior @ Unbelievable Price & End to End service from Design to Installation in Transparent pricing and On-time delivery. No Hidden charges @ Introspace – Bangalore’s Best Premium & Luxury Home Interior Brand. Best Interior Designers Bangalore</p>
                <center><Button variant="outline-light" className='br-0 rounded-0 px-5 py-2 mb-5'  onClick={handleShow}>Book A Free Expert Consulation for Your Dream Home</Button></center>

            </Col>
            <Col sm={5} className='colSecond'>

            </Col>
        </Row>
        <Mod show={show} handleClose={handleClose} />
    </>
  )
}

export default Best