import React,{ useState} from 'react';
import './Footer.css';
import { Button, Col, Row } from 'react-bootstrap';
import Mod from '../utils/Mod';

function Footer() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
        <Row className='footerRow'>
            <Col>
                <h2 className='footerh2'>READY TO WORK TOGETHER?</h2>
                <center><Button variant="outline-light" className='br-0 rounded-0 px-5 py-2'  onClick={handleShow}>GET A FREE QUOTE</Button></center>
            </Col>
        </Row>
        <Mod show={show} handleClose={handleClose} />
    </>
  )
}

export default Footer