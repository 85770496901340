import React from 'react';
import './Header.css';
import { Container, Row, Col, Navbar, Nav, Button, Offcanvas } from 'react-bootstrap';
import logo from '../assets/logo.png';



function Header() {
  return (

      //  <Navbar expand={'sm'}  className='p-0'>
       
      //     <Container fluid className='p-0'>
      //     <Navbar.Brand href="#home">
      //       <img
      //         src={require("../assets/logo.png")}
      //         width="20%"
      //         height="auto"
      //         className="d-inline-block align-top"
      //         alt="React Bootstrap logo"
      //       />
      //     </Navbar.Brand>
      //   <Navbar.Toggle aria-controls="basic-navbar-nav" />
      //   <Navbar.Collapse id="basic-navbar-nav">
      //     <Nav className="me-auto">
      //       <Nav.Link href="#home">Home</Nav.Link>
      //       <Nav.Link href="#link">Our Services</Nav.Link>
      //       <Nav.Link href="#link">How it Works</Nav.Link>
      //       <Nav.Link href="#link">Our Portfolio</Nav.Link>
      //       <Nav.Link href="#link">Contact Us</Nav.Link>
      //     </Nav>
      //   </Navbar.Collapse>
      // </Container>
      //  </Navbar>
      <>
      <Navbar expand={'md'} className="bg-body-light pb-0 headerNav">
      <Container fluid>
        <Navbar.Brand href="#" className='firstNav'>
          <img
            src={require("../assets/logo.png")}
            className='imgNav'
            // className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-md`}
          aria-labelledby={`offcanvasNavbarLabel-expand-md`}
          // placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
            <img
            src={require("../assets/logo.png")}
            className="imgNav"
            alt="React Bootstrap logo"
          />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-center flex-grow-1 pe-3 text-dark">
              <Nav.Link href="#Home" className="text-dark">
                Home
              </Nav.Link>
              <Nav.Link href="#Services" className="text-dark">
                Our Services
              </Nav.Link>
              <Nav.Link href="#Work"  className="text-dark">
                How it Works
              </Nav.Link>
              <Nav.Link href="#Portfolio"  className="text-dark">
                Our Portfolio
              </Nav.Link>
              <Nav.Link href="#Contact" className="text-dark">
                Contact Us
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
    
</>

  );
};

  
 

export default Header