// src/components/ImageGallery.js
import React, { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const ImageGallery = ({ images }) => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [preloadImages, setPreloadImages] = useState([]);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxIsOpen(true);
  };

  const closeLightbox = () => {
    setLightboxIsOpen(false);
  };

  useEffect(() => {
    const preload = images.map((image) => {
      const img = new Image();
      img.src = image.url;
      return img;
    });
  
    setPreloadImages(preload);
  }, [images]);

  return (
    <div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {images.map((image, index) => (
          <div key={index}  className="col-12 col-md-4 p-2">
            <img
              src={image.thumbnailUrl}
              alt={`Thumbnail ${index + 1}`}
              onClick={() => openLightbox(index)}
              style={{ cursor: 'pointer', width: '100%', height: 'auto' }}
              loading="lazy"
            />
          </div>
        ))}
      </div>

      {lightboxIsOpen && (
        <Lightbox
          mainSrc={images[photoIndex].url}
          nextSrc={images[(photoIndex + 1) % images.length].url}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].url}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </div>
  );
};

export default ImageGallery;
