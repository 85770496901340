import React, { useState } from 'react';
import { Col, Row, Accordion, Button  } from 'react-bootstrap'
import { Gallery } from "react-grid-gallery";
import ImageGallery from './ImageGallery';
import './Services.css';
import Mod from '../utils/Mod';

function Portfolio() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


const images = [
    { url: require('../assets/portfolio/1.jpg'), thumbnailUrl: require('../assets/portfolio/1.jpg') },
    { url: require('../assets/portfolio/2.jpg'), thumbnailUrl: require('../assets/portfolio/2.jpg') },
    { url: require('../assets/portfolio/3.jpg'), thumbnailUrl: require('../assets/portfolio/3.jpg') },
    { url: require('../assets/portfolio/4.jpg'), thumbnailUrl: require('../assets/portfolio/4.jpg') },
    { url: require('../assets/portfolio/5.jpg'), thumbnailUrl: require('../assets/portfolio/5.jpg') },
    { url: require('../assets/portfolio/6.jpg'), thumbnailUrl: require('../assets/portfolio/6.jpg') },
    { url: require('../assets/portfolio/7.jpg'), thumbnailUrl: require('../assets/portfolio/7.jpg') },
    { url: require('../assets/portfolio/8.jpg'), thumbnailUrl: require('../assets/portfolio/8.jpg') },
    { url: require('../assets/portfolio/9.jpg'), thumbnailUrl: require('../assets/portfolio/9.jpg') },
    
    // Add more images as needed
  ];


  return (
    <>
    <Row className='firstRowSer' id='Portfolio'>
    <Col className='border-bottom border-dark border-2 p-0'>
    <h2 className='H2Ser'>OUR PORTFOLIO</h2>
    </Col>
    </Row>
    <Row className='SecondRowSer'>
    <Col className='' sm={12}>
    <ImageGallery images={images} />
    <center><Button style={{backgroundColor:'#163664',}} className='portButton mt-0 mt-sm-5 mt-md-1 fs-5'  onClick={handleShow}>Contact Us Now!</Button></center>


    </Col>

   
    </Row>

    <Mod show={show} handleClose={handleClose} />
    </>
  )
}

export default Portfolio