import React, { useState, useRef } from 'react'
import './Banner.css'
import { Form, Row, Col, Button } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';
import ContactForm from '../utils/ContactForm';



function Banner() {
 
  return (
    <Row className='backgroundContainer py-5' id='Banner'>
        <Col sm={12} md={8} className='firstRowCol'>
        {/* <h1 className='text-dark firstRowh1'>Complete Home Interior Services</h1> */}
        {/* <p  className='text-dark firstRowp'>Make Your Home Look Beautiful</p> */}
        </Col>
        <Col sm={12} md={4} className='px-5 mobileCol' style={{}}>
        <ContactForm />
        </Col>
    </Row>
   
  )
}

export default Banner;

const styles = StyleSheet