import React from 'react';
import './Last.css';
import { Col, Row } from 'react-bootstrap';

function Last() {
  return (
    <Row className='bg-light pt-3 pb-3 mb-4 mb-md-0' id='Contact'>
        <Col className=''>
            <h3 className='lastH3 text-center'>Copyright © 2023 IntroSpace. All rights reserved.</h3>
           
            
        </Col>
        
    </Row>
  )
}

export default Last