import React, { useState, useRef } from 'react'
import '../component/Banner.css';
import './ContactForm.css';
import { Form, Row, Col, Button } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';

const Result = () =>{
    return(
        <p>Your message has been successfully sent. We'll contact you soon</p>
    )
  };

function ContactForm() {
    const form = useRef();
  const [result, showResult] = useState(false);
  const navigation = useNavigate();


 
  const sendEmail = async  (e) => {
      e.preventDefault();
  
      
    
      emailjs.sendForm('service_gyb5wvi', 'template_7zbajxy', form.current, 'JxxYo8z7JRpJc4wMR')
        .then((result) => {
            console.log(result.text);
            // navigation('/thank-you');
        }, (error) => {
            console.log(error.text);
        });
        // e.target.reset();
        // showResult(true);
   
    emailjs.sendForm('service_x44fl28', 'template_dvqq5pq', form.current, '__-Bbi46YRqTAkieA')
    .then((result) => {
        console.log(result.text);
        navigation('/thank-you');
    }, (error) => {
        console.log(error.text);
    });
    e.target.reset();
    showResult(true);
};

    // hide result after work 

    setTimeout(()=>{
      showResult(false);
    }, 5000);
  return (
    <div className='FormSection'>
        <h2 className=' text-center H2Contact'>Book Now & Get Flat 25 % Off</h2>
            <Form ref={form} onSubmit={sendEmail}>
            <Form.Control size="lg" type="text" placeholder="Name" className='my-3' required name='fullName'/>
            
            <Form.Control size="lg" type="number" placeholder="+91" className='my-3' required name='phone' />
           
            <Form.Control size="lg" type="email" placeholder="E-mail" className='my32' required  name='email'/>
            
            <Form.Select size="lg" className='my-3' required name='bhk'>
                 <option value="" disabled>No. of BHK / Villa</option>
                 <option value="2BHK">2BHK</option>
                 <option value="3BHK">3BHK</option>
                 <option value="4BHK">4BHK</option>
                 <option value="Independent House">Independent House</option>
                 <option value="Villa">Villa</option>
            </Form.Select>

            <Form.Select size="lg" className='my-3' required name='budget'>
                <option value="" disabled>Budget</option>
                <option value="5-10 Lakhs">5-10 Lakhs</option>
                <option value="10-20 Lakhs">10-20 Lakhs</option>
                <option value="20-30 Lakhs">20-30 Lakhs</option>
                <option value="Above 30 Lakhs">Above 30 Lakhs</option>
            </Form.Select>
            <Button type="submit" variant="dark" className='w-100' size="lg" style={{backgroundColor:'#163664'}}>Request a Call Back</Button>
            </Form>
            </div>
  )
}

export default ContactForm