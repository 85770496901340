import React, {useState} from 'react';
import './Services.css';
import { Col, Row, Accordion, Button  } from 'react-bootstrap'
import Mod from '../utils/Mod';

function Works() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
    <Row className='firstRowSer' id='Work'>
    <Col className='border-bottom border-dark border-2 p-0'>
    <h2 className='H2Ser'>HOW IT WORKS</h2>
    </Col>
    </Row>
    <Row className='SecondRowSer'>
    <Col className='p-0' sm={12}>
    <img src={require('../assets/Screenshot-2023-10-06-232557.png')} style={{width:'100%'}}  className='centerButton'/>
    <center>
      <Button className='buttonWork  fs-5'  style={{backgroundColor:'#163664'}} onClick={handleShow}>Book a Free Consultation</Button>
    </center>
    </Col>

   
    </Row>
    <Mod show={show} handleClose={handleClose} />

    </>
  )
}

export default Works