import React from 'react';
import './Services.css';
import './House.css';
import { Col, Row, Accordion  } from 'react-bootstrap';

function House() {
  return (
    <>
    <Row className='firstRowSer'>
    <Col className='border-bottom border-dark border-2 p-0'>
    <h2 className='H2House'><span style={{fontWeight:'500'}}>HOW INTROSPACE WORKS</span> FOR YOUR DREAM HOUSE</h2>
    </Col>
    </Row>
    <Row className='SecondRowSer'>
    <Col xs={6} sm={6} md={2}>
    <center>
    <img src={require('../assets/icons/house.png')} style={{width:70,height:70}} />
    <h3 className='fs-5' style={{fontWeight:'bold',color:'#163664'}}>Customization​</h3>
    <p className='text-secondary' style={{fontSize:'1em'}}>Your Dream Home, Your Way​</p>
    </center>
    </Col>
    <Col xs={6} sm={6} md={2}>
    <center>
    <img src={require('../assets/icons/money.png')} style={{width:70,height:70}}  />
    <h3 className='fs-5' style={{fontWeight:'bold',color:'#163664'}}>Affordability​</h3>
    <p className='text-secondary' style={{fontSize:'1em'}}>No-Cost, easy EMIs Available​</p>
    </center>
    </Col>
    <Col xs={6} sm={6} md={2}>
    <center>
    <img src={require('../assets/icons/exclusive.png')} style={{width:70,height:70}}  />
    <h3 className='fs-5' style={{fontWeight:'bold',color:'#163664'}}>Exclusive</h3>
    <p className='text-secondary' style={{fontSize:'1em'}}>Italian designs at Indian prices​​</p>
    </center>
    </Col>
    <Col xs={6} sm={6} md={2}>
    <center>
    <img src={require('../assets/icons/settings.png')} style={{width:70,height:70}}  />
    <h3 className='fs-5' style={{fontWeight:'bold',color:'#163664'}}>One-Stop Solutions</h3>
    <p className='text-secondary' style={{fontSize:'1em'}}>End-to-end Services​</p>
    </center>
    </Col>
    <Col xs={6} sm={6} md={2}>
    <center>
    <img src={require('../assets/icons/fast.png')} style={{width:70,height:70}}  />
    <h3 className='fs-5' style={{fontWeight:'bold',color:'#163664'}}>Quick Delivery​</h3>
    <p className='text-secondary' style={{fontSize:'1em'}}>45-Day Guarantee​</p>
    </center>
    </Col>
    <Col xs={6} sm={6} md={2}>
    <center>
    <img src={require('../assets/icons/interior-design.png')} style={{width:70,height:70}}  />
    <h3 className='fs-5' style={{fontWeight:'bold',color:'#163664'}}>Form & Function</h3>
    <p className='text-secondary' style={{fontSize:'1em'}}>Stylish and space-saving​​</p>
    </center>
    </Col>
    </Row>
    </>
  )
}

export default House