import React from 'react'
import { Col, Row, Accordion  } from 'react-bootstrap';
import './Services.css';

function FAQ() {
  return (
    <>
    <Row className='firstRowSer'>
    <Col className='border-bottom border-dark border-2'>
    <h2 className='H2House'><span style={{fontWeight:'500'}}>FREQUENTLY</span> ASKED QUESTIONS</h2>
    </Col>
    </Row>
    <Row className='SecondRowSer'>
    <Col>
    <Accordion defaultActiveKey="0" >
      <Accordion.Item eventKey="0">
        <Accordion.Header><b className='text-secondary'>Why do I need an interior designer?</b></Accordion.Header>
        <Accordion.Body className='text-secondary'>
        Have you been envisioning your perfect home but unsure how to make it a reality? This is where an interior designer steps in. They understand your way of life and preferences, providing personalized design strategies that elevate your living experience. With their proficiency, they’ll guide you throughout the whole journey, from conceptualization, picking materials, liaising with contractors and vendors, to adding the finishing touches to your dream abode. Additionally, they can assist you in crafting a stylish yet practical home within your budget.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header><b className='text-secondary'>Do you offer free consultations or quotes for your services?</b></Accordion.Header>
        <Accordion.Body className='text-secondary'>
        Certainly, we provide complimentary consultations and estimates for our services. You’re welcome to set up a meeting with one of our design specialists to go over your needs and receive a projected cost for the task. Additionally, our team will guide you through our design methodology and show you how we can assist in realizing your vision.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header><b className='text-secondary'>Can you provide references or examples of past projects you have completed?</b></Accordion.Header>
        <Accordion.Body className='text-secondary'>
        Certainly, we can offer references and showcase previous projects we’ve undertaken. Please check out our website to see our work portfolio. Additionally, our YouTube channel features testimonials from our satisfied clients.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header><b className='text-secondary'>What services are included under home interior design?</b></Accordion.Header>
        <Accordion.Body className='text-secondary'>
        Introspace delivers comprehensive solutions that encompass design, 3D and VR visualizations, civil tasks, electrical services, wall finishes, painting, plumbing, tiling, flooring, false ceilings, modular interior setups, and additional installations.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header><b className='text-secondary'>Why do I need an interior designer?</b></Accordion.Header>
        <Accordion.Body className='text-secondary'>
        Engaging an interior designer is not just about aesthetics but also about functionality and optimizing the use of space. An interior designer possesses the expertise to envision and execute a design that aligns with your tastes, preferences, and lifestyle. Their in-depth knowledge of materials, colors, spatial arrangements, and the latest trends allows them to curate spaces that are both beautiful and functional. Beyond the visual appeal, designers understand the intricacies of project management, ensuring that your interior projects are completed on time and within budget. They also have access to resources and professionals in the industry, which can save you time, money, and potential pitfalls. In essence, hiring an interior designer can transform your living space into a cohesive, comfortable, and efficient environment, tailored specifically to your needs and desires.
        </Accordion.Body>
      </Accordion.Item>
      
   
    </Accordion>
    </Col>
    </Row>
    </>
  )
}

export default FAQ