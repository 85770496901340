import React from 'react';
import './Thanks.css';
import '../component/House.css';
import '../component/Services.css';
import { Col, Container, Row } from 'react-bootstrap'

function Thanks() {
  return (
    <Container fluid>
      <Row className='firstRowSer'>
        <Col className='border-bottom border-dark border-2'>
          <h1 className="H2House">THANK YOU!</h1>
        </Col>
      </Row>
      <Row className='backgroundRow'>
        <Col>
          <h2 className="text-center text-light thanksH2">Thank You For Your Enquiry</h2>
          <p className="text-center text-light thanksHP">Thank you for choosing Introspace for your interior design needs.</p>
        </Col>
      </Row>
    </Container>
  )
}

export default Thanks