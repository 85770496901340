import React from 'react'
import Header from '../component/Header'
import Banner from '../component/Banner'
import { Container, Row, Col } from 'react-bootstrap'
import Footer from '../component/Footer'
import FAQ from '../component/FAQ'
import House from '../component/House'
import Services from '../component/Services'
import Works from '../component/Works'
import Portfolio from '../component/Portfolio'
import Provide from '../component/Provide'
import Best from '../component/Best'
import Last from '../component/Last'


function Main() {
  return (
    <Container fluid id='Home'>
      <Header />
      <Banner />
      <House />
      <Services />
      <Works/>
      <Portfolio />
      {/* <Provide /> */}
      <FAQ />
      
      <Best />
      <Footer />
      <Last />
      <a href='https://wa.me/918971954001' class='d-none d-md-block'>
      <img src={require('../assets/whatsapp.png')}
      style={{
          width: 50,
          height: 50,
          position: 'fixed',
          bottom: 10,
          right: 10, 
        }}
        alt="WhatsApp Icon"
        
      /></a>
      <a href='tel:+918971954001'  class='d-none d-md-block'>
      <img src={require('../assets/telephone.png')}
      style={{
          width: 50,
          height: 50,
          position: 'fixed',
          bottom: 10, 
          left: 10, 
        }}
        alt="Telephone Icon"
      
      /></a>
      <Row style={{position:'fixed',bottom:0,left:12,width:'100%',height:'5vh'}} className='d-md-none d-xs-block d-sm-block justify-content-center'>
        <Col xs={6} sm={6} style={{backgroundColor:'#2196f3',justifyContent:'center',alignItems:'center'}}>
        <a href='tel:+918971954001' style={{color:'white',textDecoration:'none'}} >
        <center style={{paddingTop:'3%'}}>
      <img src={require('../assets/telephone.png')}
      style={{
          width: 20,
          height: 20,
          
        }}
        alt="Telephone Icon"
      />Call Now</center></a>
        </Col>
        <Col xs={6} sm={6} style={{backgroundColor:'#4dc247'}}>
        
        <a href='https://wa.me/918971954001' style={{color:'white',textDecoration:'none'}}>
        <center  style={{paddingTop:'3%'}}>
      <img src={require('../assets/whatsapp.png')}
      style={{
          width: 20,
          height: 20,
         
        }}
        alt="WhatsApp Icon"
      /> Whatsapp</center></a>
        </Col>
      </Row>
    </Container>
  )
}

export default Main