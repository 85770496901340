import React,{ useState} from 'react';
import './Services.css'
import { Col, Row, Accordion, Button  } from 'react-bootstrap'
import Mod from '../utils/Mod';

function Services() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
    <Row className='firstRowSer' id='Services'>
    <Col className='border-bottom border-dark border-2 p-0'>
    <h2 className='H2Ser' style={{textTransform:'uppercase'}}> </h2>
    <h2 className='H2House'  style={{textTransform:'uppercase'}}><span style={{fontWeight:'500'}}>Best & Luxury Interior</span> Design Services in Bangalore</h2>

    </Col>
    </Row>
    <Row className='SecondRowSer'>
    <Col className='SecondCol' md={4}>
    <center className='border border-dark p-3'>
    <img src={require('../assets/services/2.jpg')} style={{width:'95%'}} />
    <h3 className='fs-5 mt-3' style={{fontWeight:'bold',color:'#163664'}}>Home Interiors</h3>
    <p className='text-secondary' style={{fontSize:'1em'}}>We provide comprehensive interior design services for homes, tailored to fit every style and budget, along with expert project management.</p>
    <Button style={{backgroundColor:'#163664'}} className='mb-5' onClick={handleShow}>Enquire Now & Get 25% Off</Button>
    </center>
    </Col>
    <Col className='SecondCol' md={4}>
    <center className='border border-dark p-3'>
    <img src={require('../assets/services/1.jpg')}  style={{width:'95%'}} />
    <h3 className='fs-5 mt-3' style={{fontWeight:'bold',color:'#163664'}}>Modular Kitchens</h3>
    <p className='text-secondary' style={{fontSize:'1em'}}>We provide custom-made, high-quality modular kitchens in various styles and finishes.</p>
    <Button style={{backgroundColor:'#163664'}} className='mb-5 mt-4' onClick={handleShow}>Enquire Now & Get 25% Off</Button>
    </center>
    </Col>
    <Col className='SecondCol' md={4}>
    <center className='border border-dark p-3'>
    <img src={require('../assets/services/3.jpg')}  style={{width:'95%'}}  />
    <h3 className='fs-5 mt-3' style={{fontWeight:'bold',color:'#163664'}}>Modular Wardrobes</h3>
    <p className='text-secondary' style={{fontSize:'1em'}}>We design premium modular wardrobes, wall partitions and Wallpaper according to trending themes.​​</p>
    <Button style={{backgroundColor:'#163664'}} className='mb-5 mt-4' onClick={handleShow}>Enquire Now & Get 25% Off</Button>
    </center>
    </Col>
   
    </Row>


    <Row className='SecondRowSer'>
    <Col className='SecondCol' md={4}>
    <center className='border border-dark p-3'>
    <img src={require('../assets/services/4.jpg')} style={{width:'95%'}} />
    <h3 className='fs-5 mt-3' style={{fontWeight:'bold',color:'#163664'}}>TV Wall Units</h3>
    <p className='text-secondary' style={{fontSize:'1em'}}>We design signature TV Wall Units for your dream home.</p>
    <Button style={{backgroundColor:'#163664'}} className='mb-5 mt-5' onClick={handleShow}>Enquire Now & Get 25% Off</Button>
    </center>
    </Col>
    <Col className='SecondCol' md={4}>
    <center className='border border-dark p-3'>
    <img src={require('../assets/services/5.jpg')}  style={{width:'95%'}} />
    <h3 className='fs-5 mt-3' style={{fontWeight:'bold',color:'#163664'}}>Decor & Furnishing</h3>
    <p className='text-secondary' style={{fontSize:'1em'}}>We assist you in choosing the perfect decor and furnishings to bring your home to life.</p>
    <Button style={{backgroundColor:'#163664'}} className='mb-5 mt-4' onClick={handleShow}>Enquire Now & Get 25% Off</Button>
    </center>
    </Col>
    <Col className='SecondCol' md={4}>
    <center className='border border-dark p-3'>
    <img src={require('../assets/services/6.jpg')}  style={{width:'95%'}}  />
    <h3 className='fs-5 mt-3' style={{fontWeight:'bold',color:'#163664'}}>Furniture</h3>
    <p className='text-secondary' style={{fontSize:'1em'}}>We provide an extensive selection of furniture, from classic to contemporary styles, and from solid wood to sleek high gloss finishes.​​</p>
    <Button style={{backgroundColor:'#163664'}} className='mb-4 mt-4' onClick={handleShow}>Enquire Now & Get 25% Off</Button>
    </center>
    </Col>
   
    </Row>


    <Row className='SecondRowSer'>
    <Col className='SecondCol' md={4}>
    <center className='border border-dark p-3'>
    <img src={require('../assets/services/7.jpg')} style={{width:'95%'}} />
    <h3 className='fs-5 mt-3' style={{fontWeight:'bold',color:'#163664'}}>Villa Interiors</h3>
    <p className='text-secondary' style={{fontSize:'1em'}}>Transforming villas into luxurious havens with bespoke interior designs.</p>
    <Button style={{backgroundColor:'#163664'}} className='mb-5 mt-5' onClick={handleShow}>Enquire Now & Get 25% Off</Button>
    </center>
    </Col>
    <Col className='SecondCol' md={4}>
    <center className='border border-dark p-3'>
    <img src={require('../assets/services/8.jpg')}  style={{width:'95%'}} />
    <h3 className='fs-5 mt-3' style={{fontWeight:'bold',color:'#163664'}}>Turnkey Interior Solutions</h3>
    <p className='text-secondary' style={{fontSize:'1em'}}>Seamless turnkey interior solutions, from conception to completion.</p>
    <Button style={{backgroundColor:'#163664'}} className='mb-5 mt-5' onClick={handleShow}>Enquire Now & Get 25% Off</Button>
    </center>
    </Col>
    <Col className='SecondCol' md={4}>
    <center className='border border-dark p-3'>
    <img src={require('../assets/services/9.jpg')}  style={{width:'95%'}}  />
    <h3 className='fs-5 mt-3' style={{fontWeight:'bold',color:'#163664'}}>Apartment Interiors</h3>
    <p className='text-secondary' style={{fontSize:'1em'}}>We provide comprehensive interior design services for apartments, tailored to fit every style and budget, along with expert project management.​​</p>
    <Button style={{backgroundColor:'#163664'}} className='mb-5 mt-4' onClick={handleShow}>Enquire Now & Get 25% Off</Button>
    </center>
    </Col>
   
    </Row>
    <Mod show={show} handleClose={handleClose} />
    </>
  )
}

export default Services